import { Container } from '@/shared/ui/container';
import { tableItems } from './config/table.config';
import { Button } from '@/shared/ui/button';
import { useLinks } from '@/shared/context/links.context';
import styles from './styles.module.scss';

export const Info = () => {
  const links = useLinks();

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>Casino7 официальный сайт казино</h1>
          <div className={styles.desc}>
            <p>
              7к casino - это новое онлайн казино, которая работает на игровом
              рынке с 2023 года. Игровой оператор предлагает развитую бонусную
              систему, оперативный вывод выигрышей, широкое разнообразие слотов
              и бесперебойный доступ к своему ресурсу за счет развитой системы
              рабочих зеркал. Минимальный депозит в онлайн казино 7k составляет
              2 EUR/USD. Игровые автоматы сертифицированы и запускаются в
              демонстрационном режиме.
            </p>
          </div>
          <div className={styles.table}>
            {tableItems.map((item) => (
              <div key={item.label} className={styles.row}>
                <span className={styles.label}>{item.label}</span>
                <span className={styles.value}>{item.value}</span>
              </div>
            ))}
          </div>
          <h2 className={styles.title}>Информация о казино 7С</h2>
          <div className={styles.desc}>
            <p>
              7к казино официальный сайт которого, принимает игроков со всего
              мира. Доступ к игре на деньги открыт только зарегистрированным, и
              совершеннолетним пользователям. Игровые автоматы можно запускать
              как в браузере десктопного устройства, так и в мобильном
              приложении. Ассортимент развлечений, насчитывает больше 2 ООО
              азартных таитлов от ведущих мировых провайдеров, В 7k онлайн
              казино игрок получит доступ к игровым автоматам, слотам с
              моментальным джекпотом, карточным и настольным играм, рулетке,
              Live-дилерам и ставкам на спорт.
            </p>
            <p>
              Азартный клуб имеет обширную клиентскую базу и высокую репутацию.
              7к casino предлагает зарегистрированным пользователям депозитные и
              без депозитные бонусы, фриспины, а также индивидуальные поощрения.
              Оператор 7к имеет официальные страницы в популярных социальных
              сетях: Facebook, ВКонтакте, lnstagram„ На сегодняшний день идет
              активное развитие Те!едгат-канала. Служба саппорта 7 к казино
              работает в режиме 24/7, предоставляя обратную связь игрокам на
              русском и английском языках.
            </p>
          </div>
          <Button
            className={styles.btn}
            variant="blue"
            href={links?.welcome_bonus}
          >
            welcome бонус
          </Button>
        </div>
      </Container>
    </section>
  );
};
